import { atom } from "recoil";

export const loaderState = atom({
  key: "loaderState",
  default: true,
});

export const orderInfoState = atom({
  key: "orderInfoState",
  default: {
    orderId: "-",
    trackingId: "-",
    orderStatus: "-",
    orderStatusDate: "-",
    orderStatusHistory: [
      {
        status: "-",
        location: "-",
        updatedAtZoned: "-",
      },
    ],
    customerCode: "-",
    ChangeLocation: false,
    geolocationPresent: true,
    recordAbsent: false,
    initialLat: null,
    initialLng: null,
    showMapModal: false,
    isLocationVerified: false,
    onlyShowMap: false,
    accuracy: null, // Geo location accuracy.
    timestamp: null, // Timestamp received when we access current location.
    center: {
      latitude: null,
      longitude: null,
    },
  },
});

export const customerInfoState = atom({
  key: "customerInfoState",
  default: {
    name: "",
    address: "-",
    city: "",
    state: "",
    country: "",
    pincode: "",
    center: {
      latitude: null,
      longitude: null,
    },
    address1: "",
    address2: "",
  },
});

export const modalState = atom({
  key: "modalState",
  default: false,
});

export const configValues = atom({
  key: "configValues",
  default: {
    showStatus: false,
    showMap: false,
    showStepper: false,
    allowAddressEdit: false,
    showAddress: false,
    hostUrl: "",
  },
});

export const DeliveryAgentInfoState = atom({
  key: "DeliveryAgentInfoState",
  default: {
    name: "",
    center: {
      latitude: null,
      longitude: null,
    },
  },
});

export const SellerInfoState = atom({
  key: "SellerInfoState",
  default: {
    name: "",
    center: {
      latitude: null,
      longitude: null,
    },
  },
});

export const IsHyperlocalError = atom({
  key: "IsHyperlocalError",
  default: false,
});
