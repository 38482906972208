export const createHeaders = (headersArray) => {
    let headers = {};
    if(headersArray){
        headersArray.map(headerContent => {
            const key = headerContent[0];
            const value = headerContent[1];
            headers[key] = value;
        });
    }
    return headers;
};


export const defaultHeader = [
    ["Content-Type", "application/json"]
];