import { lazy } from "react";
import OrderTrackingHL from "../pages/hyperlocal/order-tracking.component";
const OrderInput = lazy(() =>
  import("../components/order-input/order-input.component")
);
const PageContainer = lazy(() =>
  import("../pages/order-tracking/page-container.component")
);
const routes = [
  {
    path: "/",
    component: OrderInput,
  },
  {
    path: "/track/:trackingID",
    component: PageContainer,
  },
  // {
  //   path: "/order-/:trackingID",
  //   component: PageContainer,
  // },
  {
    path: "/staging/:trackingID",
    component: PageContainer,
  },
  {
    path: "/order/:trackUrl",
    component: OrderTrackingHL,
  },
  {
    path: "/hlstage/:trackUrl",
    component: OrderTrackingHL,
  },
];

export default routes;
