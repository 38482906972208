import React from "react";
import "./fe-details.styles.scss";
import feIcon from "../../../../assets/images/feIcon.svg";
import phoneIcon from "../../../../assets/images/call.svg";
import { useRecoilValue } from "recoil";
import {
  DeliveryAgentInfoState,
  orderInfoState,
} from "../../../../common/recoil/atoms";

function FEDetails() {
  const feDetails = useRecoilValue(DeliveryAgentInfoState);

  const order_data = [
    {
      status: " is on the way to pick up your order",
      state: "OUT_FOR_PICKUP",
    },
    {
      status: " is picking up your order",
      state: "ARRIVED_AT_PICKUP_LOCATION",
    },
    {
      status: " has picked up your order",
      state: "PICKED_DONE",
    },
    {
      status: " has arrived at your delivery location",
      state: "ARRIVED_AT_DELIVERY_LOCATION",
    },
    {
      status: " has delivered your order",
      state: "DELIVERED",
    },
    {
      status: " is returning to Seller",
      state: "UNDELIVERED",
    },
    {
      status: " is returning to Seller",
      state: "RTO",
    },
    {
      status: " has returned order to Seller",
      state: "RTO_DELIVERED",
    },
    {
      status: "Order Cancelled",
      state: "CANCELLED",
    },
  ];

  const orderState = useRecoilValue(orderInfoState);
  const currOrderStatus = orderState.orderStatus;

  function getOrderStatusFunctionFE(order_data, currOrderStatus) {
    let orderStatus = "";
    for (let i = 0; i < order_data.length; i++) {
      if (currOrderStatus === order_data[i].state) {
        orderStatus = order_data[i].status;
      }
    }
    return orderStatus;
  }
  const getCurrOrderStatusFE = getOrderStatusFunctionFE(
    order_data,
    currOrderStatus
  );

  return (
    <div className="fe-container">
      <div className="fe-container-inner-container">
        <img src={feIcon} />
        <div className="fe-container-message">
          <span style={{ color: "#0B1323" }}>{feDetails.name}</span>
          <span>{getCurrOrderStatusFE}</span>
        </div>
      </div>
    </div>
  );
}

export default FEDetails;
