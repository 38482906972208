import axios from "axios";
import { getClientType } from "../../utils/common.utils";
import { CONFIG_DATA } from "../config/baseConfig";
import { createHeaders, defaultHeader } from "./apiUtils";

const clientType = getClientType();
const API_HOST_PREFIX = CONFIG_DATA.host[clientType].hostUrl;

//const API_HOST_PREFIX = "http://localhost:8080"
export const HYDRA_API_CALL = (method, url, payload, headers) => {
  return API(method, url, payload, API_HOST_PREFIX, createHeaders(headers));
};
export const HERMES_API_CALL = (method, url, payload, headers) => {
  return API(method, url, payload, API_HOST_PREFIX, createHeaders(headers));
};
export const CALL_THIRDPARTY_API = (method, url, payload, headers) => {
  return THIRD_PARTY_API(method, url, payload, createHeaders(headers));
};

const API = (method, url, payload, server, headers) => {
  let options = {
    headers: headers,
    method,
    url: `${server}${url}`,
  };

  if (method === "POST" || method === "PATCH" || method === "PUT") {
    options.data = payload;
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const FORMAPI = (method, url, payload, server, headers) => {
  let options = {
    headers,
    method,
    url: `${server}${url}`,
  };

  if (method === "POST" || method === "PATCH") {
    let formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }
    options.data = formData;
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const THIRD_PARTY_API = (method, url, payload, headers) => {
  let options = {
    headers: headers || defaultHeader,
    method,
    url: `${url}`,
  };

  if (method === "POST" || method === "PATCH") {
    options.data = payload;
  }

  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        return res.data;
      })
      .then((respJson) => {
        resolve(respJson);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
