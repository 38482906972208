import React from "react";
import FEDetails from "./fe-details/fe-details.component";
import rectangleIcon from "../../../assets/images/rectangleIcon.svg";
import "./order-details.styles.scss";
import OrderStatus from "./order-status/order-status.component";
import DeliveryDetails from "../delivery-details/delivery-details.component";
import TimeLine from "./timeline/timeline.component";

function OrderDetails() {
  return (
    <div className="outermost-container-order-det">
      <div className="rectangle-icon-order-det">
        <img src={rectangleIcon} alt="rectangle" />
      </div>
      <OrderStatus />
      <FEDetails />
      <TimeLine />
      <DeliveryDetails />
    </div>
  );
}

export default OrderDetails;
