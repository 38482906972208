import React from "react";
import "./track.styles.scss";

function Track() {
  return (
    <div className="track">
      <div className="track-component">Track Your Order</div>
    </div>
  );
}

export default Track;
