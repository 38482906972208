import { makeStyles } from "@material-ui/core";
import React from "react";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

const useStyles = makeStyles((theme) => ({
    divV1: {
        display: "flex",
        height: "100%",
    },
    divToastError: {
        width: "3%",
        backgroundColor: "red",
    },
    divV2: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 10,
        paddingTop: 10,
        marginLeft: 15,
    },
    closeIconV1: {
        color: "red",
    },
    divTitle: {
        fontSize: 20,
        fontWeight: 300,
        letterSpacing: "1px",
    },
    divMessage: {
        fontSize: 16,
        fontWeight: "normal",
        color: "#505050",
    },
    mlV1: {
        marginLeft: 10,
    },
    divToastSuccess: {
        width: "3%",
        backgroundColor: "#00875a",
    },
    done: {
        color: "#00875a",
        fontSize: 40,
    },
}));

export default function SuccessToastMessage({ message, closeToast }) {
    const classes = useStyles();

    return (
        <div className={classes.divV1}>
            <div className={classes.divToastSuccess}>&nbsp;</div>
            <div className={classes.divV2}>
                <div>
                    <CheckCircleOutlinedIcon className={classes.done} />
                </div>
                <div className={classes.mlV1}>
                    <div className={classes.divTitle}>Success</div>
                    <div className={classes.divMessage}>{message}</div>
                </div>
            </div>
        </div>
    );
}