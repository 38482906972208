export const CONFIG_DATA = {
  host: {
    meesho: {
      showStatus: true,
      showMap: true,
      showStepper: true,
      allowAddressEdit: true,
      hostUrl: "https://api-3p.loadshare.net",
    },
    staging: {
      showStatus: true,
      showMap: true,
      showStepper: true,
      allowAddressEdit: true,
      hostUrl: "https://meesho-api-staging.loadshare.net",

    },
    hermes: {
      showStatus: true,
      showMap: true,
      showStepper: true,
      allowAddressEdit: true,
      hostUrl: "https://api.loadshare.net",
    },
    hermesStaging: {
      showStatus: true,
      showMap: true,
      showStepper: true,
      allowAddressEdit: true,
      hostUrl: "https://api-staging.loadshare.net",
    },
  },
};
export const CLIENT_TYPES = {
  meesho: "meesho",
  hermes: "hermes",
  staging: "staging",
  hermesStaging: "hermesStaging",
};
