import React from "react";
import Geocode from "react-geocode";
import Moment from "react-moment";
import moment from "moment";
import { CLIENT_TYPES } from "../common/config/baseConfig";
import SuccessToastMessage from "../components/toast-message/success-toast-message";
import ErrorToastMessage from "../components/toast-message/failure-toast-message";
import { toast } from "react-toastify";


//Function to format Timeline text
const createTimelineText = (event) => {
  return (
    <div>
      <strong>{event.status}</strong> at <strong>{event.location}</strong>
    </div>
  );
};

//Function to set Timeline details
export const getTimelineEvents = (events) => {
  return events.map((event) => {
    return {
      ts: event.timeStamp,
      text: createTimelineText(event),
    };
  });
};

//Function to get center of pincode
export const getDefaultGeolocation = async (pincode) => {
  return await Geocode.fromAddress(pincode).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      const center = {
        latitude: lat,
        longitude: lng,
      };
      return center;
    },
    (error) => {
      console.error(error);
    }
  );
};

export const getNewDateFormat = (date) => {
  const str = new Date(date).toLocaleDateString();
  return str;
};

//Function to detect the host
export const getClientType = () => {
  const url = window.location.href;
  let clientType = "meesho";
  if (url.includes("staging")) clientType = "staging";
  else if (url.includes("order")) clientType = "hermes";
  else if (url.includes("hlstage")) clientType = "hermesStaging";
  else if (url.includes("track")) clientType = "meesho";
  return CLIENT_TYPES[clientType];
};

export const relativeDate = (date) => {
  const today = moment().startOf("day");
  const getDate = moment(date).startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  if (moment(today).isSame(getDate)) return <Moment fromNow>{date}</Moment>;
  else if (moment(yesterday).isSame(getDate)) return "Yesterday";
  else return getNewDateFormat(date);
};

// Function to get time in timeline HyperLocal
export const getTime = (timeData) => {
  return moment().format("h:mm:ss");
};


export const sendSucces = (messageValue, position) => {
  toast.success(<SuccessToastMessage message={messageValue} />, {
    className: "toastContainer",
    closeOnClick: true,
    closeButton: true,
    pauseOnHover: false,
  });
};

export const sendError = (messageValue, title) => {
  toast.error(<ErrorToastMessage title={title} message={messageValue} />, {
    className: "toastContainer",
    closeOnClick: true,
    closeButton: true,
    // transition: Zoom,
    pauseOnHover: false,
  });
};