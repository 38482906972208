import { useRecoilState, useSetRecoilState } from "recoil";
import { getDefaultGeolocation } from "../../utils/common.utils";
import { HERMES_API_CALL, HYDRA_API_CALL } from "../services/apiConfig";
import { defaultHeader } from "../services/apiUtils";
import {
  loaderState,
  orderInfoState,
  customerInfoState,
  DeliveryAgentInfoState,
  SellerInfoState,
  IsHyperlocalError,
} from "./atoms";

export function useFetchOrderInfo() {
  const setLoaderState = useSetRecoilState(loaderState);
  const [order, setOrderInfo] = useRecoilState(orderInfoState);
  const [cust, setCustomerInfo] = useRecoilState(customerInfoState);
  return (trackingId, onLoad) => {
    setLoaderState(true);
    HYDRA_API_CALL("GET", `/order/info/${trackingId}`, null, defaultHeader)
      .then((res) => {
        if (res) {
          const orderDetails = res.response.orderDetails;
          const customerDetails = res.response.customerDetails;
          if (customerDetails.address1) var address = customerDetails.address1;
          if (customerDetails.address2) address += customerDetails.address2;
          if (customerDetails.address3) address += customerDetails.address3;
          setCustomerInfo({
            ...cust,
            name: customerDetails.name,
            address: address,
            pincode: customerDetails.pincode,
          });

          if (customerDetails.lat && customerDetails.lng) {
            setOrderInfo({
              ...order,
              orderId: orderDetails.orderId,
              trackingId: trackingId,
              orderStatus: orderDetails.orderStatus,
              orderStatusDate: orderDetails.orderStatusDate,
              orderStatusHistory: orderDetails.orderStatusHistory,
              clientId: orderDetails?.clientId,
              initialLat: customerDetails.lat,
              initialLng: customerDetails.lng,
              geolocationPresent : true,
              isLocationVerified: customerDetails.isLocationVerified,
              waybillNumber : orderDetails.waybillNumber,
              center: {
                latitude: customerDetails.lat,
                longitude: customerDetails.lng,
              },
            });
          }
          else if (customerDetails.pincodeLat && customerDetails.pincodeLng) {
            setOrderInfo({
              ...order,
              orderId: orderDetails.orderId,
              trackingId: trackingId,
              orderStatus: orderDetails.orderStatus,
              waybillNumber : orderDetails.waybillNumber,
              orderStatusDate: orderDetails.orderStatusDate,
              orderStatusHistory: orderDetails.orderStatusHistory,
              clientId: orderDetails?.clientId,
              initialLat: customerDetails.pincodeLat,
              initialLng: customerDetails.pincodeLng,
              geolocationPresent : false,
              isLocationVerified: customerDetails.isLocationVerified,
              center: {
                latitude: customerDetails.pincodeLat,
                longitude: customerDetails.pincodeLng,
              },
            });

          }
          else {
            /*If geolocation is not present in database default 
                    geolocation will be taken as center of pincode*/
            getDefaultGeolocation(customerDetails.pincode)
            .then(centerPincode => {
                setOrderInfo({
                  ...order,
                  orderId: orderDetails.orderId,
                  trackingId: trackingId,
                  waybillNumber : orderDetails.waybillNumber,
                  orderStatus: orderDetails.orderStatus,
                  orderStatusDate: orderDetails.orderStatusDate,
                  orderStatusHistory: orderDetails.orderStatusHistory,
                  clientId: orderDetails?.clientId,
                  geolocationPresent: false,
                  initialLat: centerPincode.latitude,
                  initialLng: centerPincode.longitude,
                  center: {
                    latitude: centerPincode.latitude,
                    longitude: centerPincode.longitude,
                  },
                });
              }
            )
            .catch(e => {
              setOrderInfo({
                ...order,
                orderId: orderDetails.orderId,
                trackingId: trackingId,
                waybillNumber : orderDetails.waybillNumber,
                orderStatus: orderDetails.orderStatus,
                orderStatusDate: orderDetails.orderStatusDate,
                orderStatusHistory: orderDetails.orderStatusHistory,
                clientId: orderDetails?.clientId,
                geolocationPresent: false
              });
            });
          }
          setLoaderState(false);
          if (onLoad) onLoad(res);
        }
      })
      .catch((err) => {
        setLoaderState(false);
        setOrderInfo({
          ...order,
          recordAbsent: true,
        });
      });
  };
}

export function useUpdateGeolocation() {
  const setLoaderState = useSetRecoilState(loaderState);
  return (trackingId, lat, lng, accuracy, timestamp, delAddress, onLoad) => {
    setLoaderState(true);
    HYDRA_API_CALL(
      "PUT",
      `/order/info/${trackingId}`,
      JSON.stringify({
        geolocation: {
          lat: lat,
          lng: lng,
        },
        accuracy: accuracy,
        timestamp: timestamp,
        deliveryAddress: delAddress,
        requestFrom: "CUSTOMER"
      }),
      defaultHeader
    )
      .then((res) => {
        if (res) {
          setLoaderState(false);
          if (onLoad) onLoad(res);
        }
      })
      .catch((err) => {
        setLoaderState(false);
      });
  };
}

//--------------*** HYPERLOCAL APIS ***---------------------

export function GetOrderDetails() {
  const [order, setOrderInfo] = useRecoilState(orderInfoState);
  const [cust, setCustomerInfo] = useRecoilState(customerInfoState);
  const [seller, setSellerInfo] = useRecoilState(SellerInfoState);
  const [fe, setFEInfo] = useRecoilState(DeliveryAgentInfoState);
  const setHyperlocalError = useSetRecoilState(IsHyperlocalError);

  return (trackUrl) => {
    HERMES_API_CALL(
      "POST",
      "/v1/hyperlocal-ops/live-tracking",
      trackUrl,
      defaultHeader
    )
      .then((res) => {
        if (res) {
          const orderDetails = res.response.orderDetails;
          const feDetails = res.response.feDetails;
          const customerDetails = res.response.customerDetails;
          const sellerDetails = res.response.sellerDetails;
          
          if (
            Object.keys(orderDetails).length === 0 &&
            Object.keys(feDetails).length === 0 &&
            Object.keys(customerDetails).length === 0 &&
            Object.keys(sellerDetails).length === 0
          ) {
            setHyperlocalError(true);
          } else {
            setCustomerInfo({
              ...cust,
              address1: customerDetails.address1,
              address2: customerDetails.address2,
              pincode: customerDetails.pincode,
              center: {
                latitude: customerDetails.lat,
                longitude: customerDetails.lng,
              },
            });
            setOrderInfo({
              ...order,
              orderId: orderDetails.orderId,
              orderStatus: orderDetails.orderStatus,
              waybillNumber : orderDetails.waybillNumber,
              orderStatusDate: orderDetails.orderStatusDate,
              orderStatusHistory: orderDetails.orderStatusHistory,
              customerCode : orderDetails.customerCode,
            });
            setFEInfo({
              ...fe,
              name: feDetails.name,
              center: {
                latitude: feDetails.lat,
                longitude: feDetails.lng,
              },
            });
            setSellerInfo({
              ...seller,
              name: sellerDetails.name,
              center: {
                latitude: sellerDetails.lat,
                longitude: sellerDetails.lng,
              },
            });
          }
        } else {
          setHyperlocalError(true);
        }
      })
      .catch((err) => {
        setHyperlocalError(true);
      });
  };
}
