import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import routes from "./routes";
const RouteComponent = () => {
    return(
        <Router>
            <Switch>
                {
                    routes.map((route, index) => {
                        return(
                            <Route 
                                key={`${route.path}-${index}`} 
                                component={route.component}
                                path={route.path}
                                exact
                            />
                        )
                    })
                }
                <Redirect exact from="/track/" to="/" />
                
            </Switch>
        </Router>
    )
};

export default RouteComponent;