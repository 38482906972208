import React from "react";
import moment from "moment";
import checkIcon from "../../../../assets/images/checkIcon.svg";
import grayStatusIcon from "../../../../assets/images/grayStatusIcon.svg";
import blueIcon from "../../../../assets/images/blueIcon.svg";
import timestampIcon from "../../../../assets/images/timestampIcon.svg";
import "./timeline.scss";
import { orderInfoState } from "../../../../common/recoil/atoms";
import { useRecoilValue } from "recoil";
// import { orderHistoryStatus } from "../order-statuses.component";

function TimeLine() {
  const orderHistoryStatus = {
    ASSIGNED: "Partner Assigned",
    ARRIVED: "Partner Assigned",
    PICKED_UP: "Order Picked Up",
    PICKEDUP: "Order Picked Up",
    PICKED_DONE: "Order Picked Up",
    ARRIVED_AT_DELIVERY_LOCATION: "Partner Reached Delivery Location",
    OUT_FOR_PICKUP: "Partner Out for Pickup",
    ARRIVED_AT_PICKUP_LOCATION: "Partner Arrived at Pickup Location",
    DELIVERED: "Delivered",
    UNDELIVERED: "Could not be Delivered",
    RTO: "Partner Out to Return the Order",
    RTO_DELIVERED: "Order Delivered Back to Pickup Location",
    CANCELLED: "Order Internally Cancelled by LSN",
    COMPLETED: "Order Delivered"
  };

  const orderState = useRecoilValue(orderInfoState);
  const orderHistory = orderState.orderStatusHistory;

  function getTimeStampCount(orderHistory) {
    let count = 0;
    for (let i = 0; i < orderHistory.length; i++) {
      if (orderHistory[i].timeStamp) {
        count++;
      }
    }
    return count;
  }

  let countTimeStamp = getTimeStampCount(orderHistory);

  return (
    <div className="timeline-container">
      {orderHistory &&
        orderHistory.map((item, index) => {
          return (
            <div className="timeline-item">
              {item.timeStamp && index < countTimeStamp - 1 ? (
                <img
                  className="timeline-item-icon"
                  src={checkIcon}
                  alt="timeline-circle"
                />
              ) : item.timeStamp && index === countTimeStamp - 1 ? (
                <img
                  className="timeline-item-icon"
                  src={blueIcon}
                  alt="timeline-circle"
                />
              ) : (
                <img
                  className="timeline-item-icon"
                  src={grayStatusIcon}
                  alt="timeline-circle"
                />
              )}

              <div className="timeline-item-content">
                <p className="timeline-item-content-p">
                  {orderHistoryStatus[item.state]}
                  {item.timeStamp && (
                    <img
                      className="timeline-item-timestamp"
                      src={timestampIcon}
                      alt="timestampIcon"
                    />
                  )}
                  <span className="tag">
                    {item.timeStamp && moment(item.timeStamp).format("h:mm A")}
                  </span>
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default TimeLine;
