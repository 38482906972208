import React from "react";
import { useRecoilValue } from "recoil";
import { customerInfoState } from "../../../common/recoil/atoms";
import "./delivery-details.scss";

function DeliveryDetails() {
  const customerDetails = useRecoilValue(customerInfoState);
  return (
    <div className="delivery-details">
      <p className="p-delivery-details">Delivering To</p>
      <p className="address1-delivery-details">{customerDetails.address1}</p>
      <p className="address2-delivery-details">{customerDetails.address2}</p>
    </div>
  );
}

export default DeliveryDetails;
