import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { GetOrderDetails } from "../../common/recoil/api";
import { IsHyperlocalError } from "../../common/recoil/atoms";
import MapComponent from "../../components/hyperlocal/map/map.component";
import OrderDetails from "../../components/hyperlocal/order-details/order-details.component";
import Track from "../../components/hyperlocal/track/track.component";
import HyperlocalError from "../hyperlocal-error/hyperlocal-error.component";

import { orderInfoState } from "../../common/recoil/atoms";

function OrderTrackingHL() {
  const trackUrl = useParams();
  const hyperlocalError = useRecoilValue(IsHyperlocalError);
  const customer= useRecoilValue(orderInfoState)?.customerCode;
  const getOrderDetailsFunction = GetOrderDetails();

  useEffect(() => {
    getOrderDetailsFunction(trackUrl);
      const intervalId = setInterval(() => {
        getOrderDetailsFunction(trackUrl);
      }, 15000);
      return () => {
        clearInterval(intervalId);
      };
  }, [hyperlocalError]);

  return (
    <div className="order-tracking-page-div">
      {hyperlocalError ? (
        <HyperlocalError />
      ) : (
        <>
          {customer == "SPENCERS" ?<></>:<Track />}
          <MapComponent isMarkerShown />
          {customer == "SPENCERS" ?<></>:<OrderDetails />}
        </>
      )}
    </div>
  );
}

export default OrderTrackingHL;
