import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
// import Footer from "./components/footer/footer.component";
import NavigationBar from "./components/navbar/navbar.component";
import RouteComponent from "./routes/routes.component";
import "normalize.css/normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "./@setproduct-ui/styles/setproduct.css";
import "./styles/common.styles.scss";
import { getClientType } from "./utils/common.utils";
import { CLIENT_TYPES } from "./common/config/baseConfig";
import Footer from "./components/hyperlocal/footer/footer.component";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";


const App = () => {
  const clientType = getClientType();
  return (
    <RecoilRoot>
      <Suspense fallback={<div>Loading....</div>}>
        {[CLIENT_TYPES.hermes, CLIENT_TYPES.hermesStaging].includes(
          clientType
        ) ? (
          ""
        ) : (
          <NavigationBar />
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          transition={Slide}
        />
        <RouteComponent />
        {/* {[CLIENT_TYPES.hermes, CLIENT_TYPES.hermesStaging].includes(
          clientType
        ) ? (
          ""
        ) : (
          <Footer />
        )} */}
        <Footer />
      </Suspense>
    </RecoilRoot>
  );
};

export default App;
