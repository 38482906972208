import React from "react";
import footerIcon from "../../../assets/logo/footerIcon.svg";
import "./footer.scss";
import { orderInfoState } from "../../../common/recoil/atoms";
import { useRecoilValue } from "recoil";

function Footer() {
  const customer = useRecoilValue(orderInfoState)?.customerCode;
  return (
      (
        customer === "SPENCERS"?
        <></>
        :
      <div className="footer-outer-div">
        <div>
          <img src={footerIcon} />
        </div>
      </div>
      )
  );
}

export default Footer;
