import React from "react";

import Navbar from "../../@setproduct-ui/core/Navbar";
import NavbarGroup from "../../@setproduct-ui/core/Navbar/Group";
import NavbarHeading from "../../@setproduct-ui/core/Navbar/Heading";
import LoadShareNetworksLogo from "../../assets/logo/loadshare_networks.png";

// import { useDevice } from "../customHooks/customHooks";

import "./navbar.styles.scss";

const NavigationBar = ({ device }) => {
    return(
        <Navbar type={device} view="flat" color="primary" fixedToTop className="navbar">
            <NavbarGroup align="center">
                <NavbarHeading>
                <img
                    src={LoadShareNetworksLogo}
                    alt="Loashare Networks"
                    className="navbar-logo"
                />
                </NavbarHeading>
            </NavbarGroup>
        </Navbar>
    )
};

export default NavigationBar;