import React from "react";
import "./hyperlocal-error.styles.scss";
import errorIcon from "../../assets/images/errorIcon.svg";

function HyperlocalError() {
  return (
    <div className="outer-div-error">
      <div className="inner-div-error">
        <img
          className="error-icon-container"
          src={errorIcon}
          alt="error-icon"
        />
        <p className="p-error-msg">Tracking URL doesn’t exist!</p>
        <p className="p-error">
          You seem to have used an incorrect tracking URL. Please recheck & try
          again.
        </p>
      </div>
    </div>
  );
}

export default HyperlocalError;
