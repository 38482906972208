import React, { useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import dropIcon from "../../../assets/images/dropIcon.svg";
import pickupIcon from "../../../assets/images/pickupIcon.svg";
import bikeIcon from "../../../assets/images/bikeIcon.svg";
import { CONFIG } from "../../../common/config/config";
import { useRecoilValue } from "recoil";
import "./map.styles.scss";
import {
  customerInfoState,
  DeliveryAgentInfoState,
  SellerInfoState,
} from "../../../common/recoil/atoms";

const containerStyle = {
  width: "400px",
  height: "350px",
};

function MapComponent() {
  const pickupLocation = useRecoilValue(SellerInfoState);
  const feLocation = useRecoilValue(DeliveryAgentInfoState);
  const dropLocation = useRecoilValue(customerInfoState);
  const center = {
    lat: feLocation.center.latitude,
    lng: feLocation.center.longitude,
  };

  const center1 = {
    lat: pickupLocation.center.latitude,
    lng: pickupLocation.center.longitude,
  };
  const center2 = {
    lat: dropLocation.center.latitude,
    lng: dropLocation.center.longitude,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: CONFIG.googleMapsKey,
  });

  const [map, setMap] = useState(/** @type google.maps.Map*/ (null));

  return isLoaded ? (
    <div className="map-div">
      <GoogleMap
        center={center}
        zoom={13}
        mapContainerStyle={containerStyle}
        onLoad={(map) => setMap(map)}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        <MarkerF
          position={center}
          icon={{
            url: bikeIcon,
            scale: 7,
          }}
        />
        <MarkerF
          position={center1}
          icon={{
            url: pickupIcon,
            scale: 7,
          }}
        />
        <MarkerF
          position={center2}
          icon={{
            url: dropIcon,
            scale: 7,
          }}
        />
      </GoogleMap>
      {/* <Button //fix locate button
        type="default"
        view="smooth"
        color="primary"
        icon="locate"
        className="current-button"
        onClick={() => map.panTo(center)}
        style={{
          right: 0,
          zIndex: 1,
          position: "fixed",
        }}
      /> */}
    </div>
  ) : (
    <></>
  );
}

export default MapComponent;
