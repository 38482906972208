import React from "react";
import "./order-status.scss";
import { useRecoilValue } from "recoil";
import { orderInfoState } from "../../../../common/recoil/atoms";

function OrderStatus() {
  const order_data = [
    {
      status: "Delivery Partner has been Assigned",
      state: "ASSIGNED",
    },
    {
      status: "Delivery Partner has been Assigned",
      state: "ARRIVED",
    },
    {
      status: "Item has been picked up",
      state: "PICKED_UP",
    },
    {
      status: "Item has been picked up",
      state: "PICKEDUP",
    },
    {
      status: " has picked up your order",
      state: "PICKED_DONE",
    },
    {
      status: "Delivery Partner has reached the delivery location",
      state: "ARRIVED_AT_DELIVERY_LOCATION",
    },
    {
      status: "Delivery Partner Out for Pickup",
      state: "OUT_FOR_PICKUP",
    },
    {
      status: "Delivery Partner has arrived at pickup location",
      state: "ARRIVED_AT_PICKUP_LOCATION",
    },

    {
      status: "Order has been successfully delivered",
      state: "DELIVERED",
    },
    {
      status: "Order could not be delivered",
      state: "UNDELIVERED",
    },
    {
      status: "Delivery Partner is out to return the order",
      state: "RTO",
    },
    {
      status: "Order successfully delivered back to pickup location",
      state: "RTO_DELIVERED",
    },
    {
      status: "Order has been internally cancelled by LSN",
      state: "CANCELLED",
    },
  ];

  const orderState = useRecoilValue(orderInfoState);
  const currOrderStatus = orderState.orderStatus;

  function getOrderStatusFunction(order_data, currOrderStatus) {
    let orderStatus = "";
    for (let i = 0; i < order_data.length; i++) {
      if (currOrderStatus === order_data[i].state) {
        orderStatus = order_data[i].status;
      }
    }
    return orderStatus;
  }
  const getCurrOrderStatus = getOrderStatusFunction(
    order_data,
    currOrderStatus
  );

  return (
    <div className="currStatus-order-status">
      <p>{getCurrOrderStatus}</p>
    </div>
  );
}

export default OrderStatus;
